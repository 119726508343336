


import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Image from 'next/image';
import web1 from '../../../public/images/Web_banner_new_1.jpg';
import web2 from '../../../public/images/Web_banner_new_2.jpg';
import web3 from '../../../public/images/Web_banner_new_3.jpg';
import web4 from '../../../public/images/Web_banner_new_1_mob.jpg';
import web5 from '../../../public/images/Web_banner_new_2_mob.jpg';
import web6 from '../../../public/images/Web_banner_new_3_mob.jpg';
import web7 from '../../../public/images/web-banner-ola-mob.jpg';
import web8 from '../../../public/images/web-banner-ola.jpg';
import sponser1 from '../../../public/images/bu4.jpeg';
import sponser2 from '../../../public/images/sponser-mob.jpeg';
import { isMobile } from 'react-device-detect';
import Analytics from "../../../common_components/Common/Analytics";
import { useSelector } from "react-redux";

export default function Bannerslider({ backgroundBannerImages }) {

    const [mobile, setMobile] = useState([])
    const [desktop, setDesktop] = useState([])
    const [specifiedImg, setSpecifiedImg] = useState([])
    const { citydata } = useSelector((state) => state.home)
    // const desktop = [ { imglink: isMobile ? web4 : web1 }, { imglink: isMobile ? web5 : web2 }, { imglink: isMobile ? web6 : web3 }];

    useEffect(() => {
        if (localStorage.getItem('currentLocationGeo') && backgroundBannerImages?.length > 0) {
            const currentloc = JSON.parse(localStorage.getItem('currentLocationGeo'))
            const addOnMob = []
            const addOnDesk = []
            backgroundBannerImages.map((data) => {
                if (data.type === 2) {
                    data?.state_id.map((state) => {
                        if (state == currentloc.state) {
                            addOnMob.push({ img: data.mob_img, link: data?.link ? data?.link : '' })
                            addOnDesk.push({ img: data.desk_img, link: data?.link ? data?.link : '' })
                        }
                    })
                }
                else if (data.type === 3) {
                    data?.city_id?.map((city) => {
                        if (city === currentloc?._id) {
                            addOnMob.push({ img: data.mob_img, link: data?.link ? data?.link : '' })
                            addOnDesk.push({ img: data.desk_img, link: data?.link ? data?.link : '' })
                        }
                    })
                } else {
                    addOnMob.push({ img: data.mob_img, link: data?.link ? data?.link : '' })
                    addOnDesk.push({ img: data.desk_img, link: data?.link ? data?.link : '' })
                }
            })
            setMobile(addOnMob)
            setDesktop(addOnDesk)
        } else if (backgroundBannerImages?.length > 0) {
            const mobile = []
            const desk = []
            backgroundBannerImages.map((data) => {
                if (data.type === 1) {
                    mobile.push({ img: data.mob_img, link: data?.link ? data?.link : '' })
                    desk.push({ img: data.desk_img, link: data?.link ? data?.link : '' })
                }
            })
            setDesktop(desk)
            setMobile(mobile)
        }
    }, [citydata, backgroundBannerImages])


    // useEffect(() => {
    //     if (backgroundBannerImages?.length > 0) {
    //         const mobile = []
    //         const desk = []
    //         const special = []
    //         backgroundBannerImages.map((data) => {
    //             if (data.type === 1) {
    //                 mobile.push(data.mob_img)
    //                 desk.push(data.desk_img)
    //             } else special.push(data)
    //         })
    //         setDesktop(desk)
    //         setMobile(mobile)
    //         setSpecifiedImg(special)
    //     }
    // }, [backgroundBannerImages])
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const handleClick = (data) => {
        const Analyticsprops = {
            GA: true,
            title: `Banner-Events`,
            category: 'Home Page-Events',
            action: `Banner-Events`,
            label: `Banner Image clicked!`,
        }
        Analytics(Analyticsprops)

    }

    return (
        <div className="home-slider">
        <Slider {...settings}>
            {
                isMobile ?
                    mobile?.map((data, index) => (
                        data?.link ?
                       
                            <a href={data?.link} target="_blank">
                                <Image sizes="100vw" loading="lazy" quality={100} width={100} height={43} src={data.img} alt="banner-img" key={index} />
                            </a>
                            :
                            <Image sizes="100vw" loading="lazy" quality={100} width={100} height={43} src={data.img} alt="banner-img" key={index} />
                    ))
                    :

                    desktop?.map((data, index) => (
                        data?.link ?
                        <a href={data?.link} target="_blank">
                            <Image sizes="100vw" loading="lazy" quality={100} width={100} height={25} src={data.img} alt="banner-img" key={index} />
                        </a>
                        :
                        <Image sizes="100vw" loading="lazy" quality={100} width={100} height={25} src={data.img} alt="banner-img" key={index} />
                ))
            }
        </Slider>
        </div>

    );
}