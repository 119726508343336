import React, { Fragment, useCallback } from 'react';
import { useState, useEffect } from 'react';
import ahmedabad from '../../public/images/ahmedabad.png';
import banglore from '../../public/images/banglore.png';
import delhi from '../../public/images/delhi.png';
import jaipur from '../../public/images/jaipur.png';
import kolkata from '../../public/images/kolkata.png';
import mumbai from '../../public/images/mumbai.png';
import pune from '../../public/images/pune.png';
import {  isMobile } from 'react-device-detect';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as homePageActions from '../../actions/homePageActions';
import * as upcomingActions from '../../actions/upcomingActions';
import { BiCurrentLocation } from 'react-icons/bi';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { FaRupeeSign } from 'react-icons/fa'
import { BsSpeedometer } from 'react-icons/bs'
import Analytics from '../../common_components/Common/Analytics';
import Bannerslider from './components/Bannerslider';
import PopularVehicles from './components/PopularVehicles'
import Router from "next/router";
import { useRouter } from 'next/router';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { toast } from 'react-toastify';
import FloatingActionButton from '../../common_components/Common/FloatingActionButton';

const cache = createCache({
  key: 'css',
  prepend: true
});



const Index = ({ history }) => {
  const router = useRouter()
  const CommonParentSection = dynamic(() => import('./components/CommonParentSection'));
  const LatestVehicles = dynamic(() => import('./components/LatestVehicles'));
  // const PopularVehicles = dynamic(() => import('./components/PopularVehicles'));
  const HomeUpcomingVehicleCard = dynamic(() => import('./components/HomeUpcomingVehicleCard'));
  const SignUpDetailsModal = dynamic(() => import('../../common_components/Common/SignUpDetailsModal'), { ssr: false });
  // const Bannerslider = dynamic(() => import('./components/Bannerslider'))
  const VehicleCompareCard = dynamic(() => import('./components/VehicleCompareCard'))
  // const LatestReviews = dynamic(() => import('./components/LatestReviews'))
  const FaqSection = dynamic(() => import('./components/HomePageFaqSection'))
  const BlogSection = dynamic(() => import('./components/BlogSection'))
  const SubscribeSection = dynamic(() => import('../../common_components/Common/SubscribeSection'), { ssr: false })
  const NewsSectionCard = dynamic(() => import('./components/NewsSectionCard'))
  const PopularBrands = dynamic(() => import('./components/PopularBrands'))

  const dispatch = useDispatch();
  const { getAllUpComingList, getAllHomeUpComingList } = bindActionCreators(
    upcomingActions,
    dispatch
  );
  const {
    getFilteredVehicles,
    getCities,
    gettoptenCompanies,
    cityselect,
    getNearByCoordinates,
    getCitybyCoordinates,
    getBackgroundBannerImage
  } = bindActionCreators(homePageActions, dispatch);
  const [popcityOptions, setPopularCityOptions] = useState([]);
  const [popularcity, setPopularCity] = useState([
    'Ahmedabad',
    'Bengaluru',
    'Delhi',
    'Jaipur',
    'Kolkata',
    'Mumbai',
    'Pune',
  ]);
  const {
    citydata,
    cityloading,
    backgroundBannerImages,
    comparevehicles
  } = useSelector((state) => state.home);
  const [isOpenSignUpModal, setIsOpenSignUpModal] = useState(false);
  const { upcomingList, loading } = useSelector(
    (state) => state.upcoming
  );
  const {
    latestElectricScooter,
    latestElectricBike,
    faqList
  } = useSelector((state) => state.home);
;
  const [sponser, setSponser] = useState(false)
  const [availableFilterData, setAvailableFilterData] = useState(null);

  useEffect(() => {
    if (localStorage.getItem('currentLocationGeo')) {
      const currentloc = JSON.parse(localStorage.getItem('currentLocationGeo'))
      if (currentloc.state === '61e66a7a2e0f24388ffccc13') setSponser(true)
      else setSponser(false)
    }
  }, [citydata])


  const LocationApi = () => {
      if (!navigator.geolocation) {
        // setStatus('Geolocation is not supported by your browser')
        toast.error("Give Geolocation access.")
      }  else {
      navigator.geolocation.getCurrentPosition((position) => {
        // setStatus(null);
        setLat(position.coords.latitude);
        const latti = position.coords.latitude
        const langi = position.coords.longitude
        setLng(position.coords.longitude);
        getCitybyCoordinates(latti, langi).then((res) => {
          const found = res.data.result[0]?.megaCity === 1 ? res.data.result[0] : res.data.result[1]
          const cityDetails = {
            label: found?.name,
            value: found?.name,
            _id: found?._id,
            state: found?.state,
            code: found?.code,
            coordinate: found?.coordinate?.coordinates
          }
          setSelectedCity(cityDetails)
          cityselect(cityDetails)
         
        })
      })
    }
    
  };

  const HandleLocation = () => {
    LocationApi();
  };

  // const [ShowResult, setShowResult] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [showCityOption, setShowCityOptions] = useState(false);
  const [budget, setBudget] = useState(3);
  const [selectedBudget, setSelectedBudget] = useState({ value: 3, label: "1.0L to 1.25L" });
  const [selectedRange, setSelectedRange] = useState({ value: 3, label: "80 to 120 kms" });
  const [topSpeed, setTopSpeed] = useState([25, 200]);
  const [trueRange, setTrueRange] = useState(3);
  const [waiting, setWaiting] = useState([0, 8]);
  const { cities } = useSelector((state) => state.home);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityLabel, setCityLabel] = useState(null);
  const [filtercity, setFilterCity] = useState('');
  // const [loadCities, setLoadCities] = useState(false);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [mainCityChanged, setMainCityChanged] = useState(false);
  const [nearby, setNearBy] = useState([])

  const [accordian, setAccordian] = useState({
    price: false,
    speed: false,
    range: false,
    waiting: false,
  });

  const [budgetOption] = useState([
    { value: 1, label: '60K to 80K' },
    { value: 2, label: '80k to 1.0L' },
    { value: 3, label: '1.0L to 1.25L' },
    { value: 4, label: '1.25L to 1.50L' },
    { value: 5, label: '1.50L to 1.75L' },
    { value: 6, label: '1.75L and above' },
  ])
  const [rangeOption] = useState([
    { value: 1, label: '0 to 40 kms' },
    { value: 2, label: '40 to 80 kms' },
    { value: 3, label: '80 to 120 kms' },
    { value: 4, label: '120 and above' }
  ])

  
  // useEffect(() => {
  //   if (!loadCities) {
  //     getCities().then((res) => {
  //       setLoadCities(true);
  //     });
  //   }
  // }, [loadCities]);


  useEffect(() => {
    setSelectedCity(citydata);
    setCityLabel(citydata.value);
    // setFilterCity(citydata.value);
  }, [citydata]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const value = JSON.parse(localStorage.getItem("currentLocationGeo"))
      if (value?._id) {
        setSelectedCity({
          label: value.value,
          value: value?.value,
          id: value?._id,
          state: value?.state,
          coordinate: value?.coordinate
        })
        setMainCityChanged(true)
      }
    }
  }, [citydata])

  useEffect(() => {
    if (selectedCity?.coordinate) {
     
      getNearByCoordinates(selectedCity?.coordinate[1], selectedCity?.coordinate[0]).then((res) => {
        // console.log(res.data)
        const value = res?.data?.result;
        const locationfinder = {
          label: (
            <div onClick={() => HandleLocation()}>
              <span className='pe-2' style={{ marginTop: '-1%' }}>
                <BiCurrentLocation />
              </span>
              Use Current Location
            </div>
          ),
          value: null,
        };
        const options = []
        options.push(locationfinder)
        options.push({
          label: "NEARBY CITIES",
          value: '',
          isDisabled: true
        })
        const LocalValue = JSON.parse(localStorage.getItem("currentLocationGeo"))
        if (mainCityChanged && LocalValue?.subCity) {
          const subCity = JSON.parse(localStorage.getItem("subCity"))
          options.push(subCity)
        }

        value?.map((data) => {
          let cityObj = {
            label: data.name,
            value: data.name,
            _id: data._id,
            state: data.state,
            code: data?.code,
            coordinate: data?.coordinate?.coordinates
          }
          options.push(cityObj);
        })
        setNearBy(options)
        setMainCityChanged(false)
      })
    }
  }, [selectedCity])

  const handlePriceChange = (data) => {
    setSelectedBudget(data)
    setBudget(data.value)
    const Analyticsprops = {
      GA: true,
      title: 'FTBV Budget Dropdown',
      category: 'FTBV Form',
      action: 'Budget dropdown',
      label: `${data.label} Budget selected`,
    }
    Analytics(Analyticsprops)
  }
  
  const handleRangeChange = (data) => {
    setSelectedRange(data)
    setTrueRange(data.value)

    const Analyticsprops = {
      GA: true,
      title: 'FTBV Range Dropdown',
      category: 'FTBV Form',
      action: 'Range dropdown',
      label: `${data.label} Range selected`,
    }
    Analytics(Analyticsprops)
  }

  const handleCityChange = (data) => {
    // console.log(data, "data");
    setSelectedCity(data);
    setCityLabel(data.value);
    setFilterCity(data.value);
    const Analyticsprops = {
      GA: true,
      title: 'FTBV City Dropdown',
      category: 'FTBV Form',
      action: 'City dropdown',
      label: `${data.value} city selected`,
    }

    Analytics(Analyticsprops)
  };

  const handleFtbvScroll = () => {
    (null);
    setCityLabel(null);
    setFilterCity('');
    if (isMobile && window.pageYOffset <= 0) window.scrollBy(0, 150)

  }

  const onSearchClick = (e) => {
    const Analyticsprops = {
      GA: true,
      title: 'Search button',
      category: 'FTBV Form',
      action: 'Search button',
      label: 'Search button clicked!',
    }
    Analytics(Analyticsprops)
   
    const queryParams = [];
  
    // Add city filter if available
    if (filtercity) {
      queryParams.push(`city=${filtercity}`);
    }
  
    // Add budget filter if available
    if (budget) {
      const budgetRanges = {
        1: '60000,80000',
        2: '80000,100000',
        3: '100000,125000',
        4: '125000,150000',
        5: '150000,175000',
        6: '175000',
      };
      const budgetRange = budgetRanges[budget];
      if (budgetRange) {
        queryParams.push(`price=${budgetRange}`);
      }
    }
  
    // Add trueRange filter if available
    if (trueRange) {
      const trueRangeValues = {
        1: '0,40',
        2: '40,80',
        3: '80,120',
        4: '120',
      };
      const trueRangeValue = trueRangeValues[trueRange];
      if (trueRangeValue) {
        queryParams.push(`truerange=${trueRangeValue}`);
      }
    }
  
    // Construct the linkObj with query parameters
    const linkObj = queryParams.length > 0 ? queryParams.join('&') : '';

    const str = selectedRange.label;
    const numbers = str.match(/\d+/g);
    const budgetStr = selectedBudget.label;
    
    const budgetNumber = budgetStr.match(/\d+(\.\d+)?[A-Za-z]+/g);
    const [startRange, endRange] = numbers.map(Number);

    // Set available filter data
    const filterData = {
      city: filtercity,
      filter_by: 3,
      truerangefrom : startRange + "km",
      truerangeto : endRange + "km",
      budgetfrom: budgetNumber[0],
      budgetto : budgetNumber[1]
    };
    setAvailableFilterData(filterData);
    
    const registered = localStorage.getItem('isRegistered');
    if (registered) {
      localStorage.removeItem('filterData')
      if (router.pathname === '/') {
        Router.push(`/electric-vehicles?${linkObj}`);
      }
      // setTimeout(() => {
      //   getFilteredVehicles(filterData);
      // }, 1000);
      // setShowResult(true);
    } else {
      setIsOpenSignUpModal(true);
    }
    // setShowResult(true);
  };

  // console.log(isOpenSignUpModal, "<=====isOpenSignUpModal");

  useEffect(() => {
    const locationfinder = {
      label: (
        <div onClick={() => HandleLocation()}>
          <span className='pe-2' style={{ marginTop: '-1%' }}>
            <BiCurrentLocation />
          </span>
          Use Current Location
        </div>
      ),
      value: null,
    };
    const optionsTitle = {
      label: 'POPULAR CITIES',
      value: '',
      isDisabled: true,
    };
   
    var optionsArray = [];
    optionsArray.push(locationfinder);
    optionsArray.push(optionsTitle);
    popularcity.map((city) => {
      let dispCity = ahmedabad;
      if (city == 'Ahmedabad') {
        dispCity = ahmedabad;
      } else if (city == 'Bengaluru') {
        dispCity = banglore;
      } else if (city == 'Delhi') {
        dispCity = delhi;
      } else if (city == 'Jaipur') {
        dispCity = jaipur;
      } else if (city == 'Kolkata') {
        dispCity = kolkata;
      } else if (city == 'Mumbai') {
        dispCity = mumbai;
      } else if (city == 'Pune') {
        dispCity = pune;
      } else {
        dispCity = ahmedabad;
      }

      optionsArray.push({
        label: (
          <div>
            <Image
              src={dispCity}
              width='20%'
              height='20%'
              alt='pop-img'
              style={{ width: '15%', paddingRight: '4%', marginTop: '-3%' }}
            />
            &nbsp; {city}
          </div>
        ),
        value: city,
      });
    });
    // console.log(optionsArray);
    setPopularCityOptions(optionsArray);

    if (cities) {
      var city = [];
      cities.map((data) => {
        let cityObj = {
          label: data.name,
          value: data?.maincitycode ? data.maincitycode : data.name,
        };
        city.push(cityObj);
      });
      setCityOptions(city);
    }
  }, [cities]);

  const handleInputchange = useCallback((value) => {
    if (value.length > 1) {
      setShowCityOptions(true);
    } else {
      setShowCityOptions(false);
    }
  }, []);

  const HandleScroll = (direction) => {
    var container = document.getElementById('upcoming-container');
    if (direction === 'left') {
      container.scrollLeft -= 265;
    } else {
      container.scrollLeft += 265;
    }
  };

  const customCityStyle = {
    placeholder(base, state) {
      return {
        ...base,
        display: state.isFocused && 'none',
      };
    },
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isDisabled && 'white',
        color: isDisabled ? '#929292' : 'black',
        fontSize: isDisabled ? '12px' : '14px',
        '&:hover': {
          backgroundColor: isDisabled ? 'white' : '#4A90E4',
          color: isDisabled ? '#929292' : 'white',
        },
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontWeight: isDisabled ? '500' : 'normal',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 9999
      }
    }
  };

  useEffect(() => {
    getAllHomeUpComingList()
    getAllUpComingList()
    getBackgroundBannerImage()
  }, [])


  return (
    <section className="new-bg-clr">
      <div className='banner '>
        {/* ----------- Banner Slider start ----------- */}

        <Bannerslider backgroundBannerImages={backgroundBannerImages} />

      </div>

      <section className='new-bg-clr  detailed-sec w-100  '>
        <div className='container  w-100 '>
          <div className='find-section'>




            {/* ----------- FTBV Form start ----------- */}

            <div className='common-parent'>
              <div className='col-12 ftbv-shadow rounded-2'>
                <h1 style={{ fontSize: '20px' }} className='col-12  px-3  py-3 new-ftbv-header rounded-2 mb-0'>Electric Vehicles in India</h1>
                <div className='new-ftbv-container row col-md-12'>
                  <div className='col-12 col-md-3 d-inline-block px-3 pt-1 pb-1 pt-md-2 pb-md-0 new-ftbv-city-border'>
                    <h2 className='fs-16 fw-500 d-sm-block d-none mb-0'>City</h2>
                    <div id="ftbv-city" className='col-12 col-md-4 col-lg-3 row align-items-center ftbv-city city-bar' style={{ zIndex: 2 }}>
                      <Select
                        placeholder={isMobile ? "Select City" : "Select"}
                        styles={customCityStyle}
                        value={selectedCity}
                        onInputChange={handleInputchange}
                        onChange={(e) => handleCityChange(e)}
                        onFocus={(e) => handleFtbvScroll(e)}
                        aria-label='ftbv-city'
                        options={showCityOption ? cityOptions : nearby.length > 1 ? nearby : popcityOptions}
                    
                      >
                        {' '}
                      </Select>
                    </div>
                  </div>
                  <div className='d-inline-block col-6 col-md-3 pt-2 pb-1 pt-md-2 pb-md-0 new-ftbv-budget-border   px-3'>
                    <h2 className='fs-16 fw-500 mb-0'>Budget</h2>
                    <div className='col-12 budget-container'>
                      <FaRupeeSign className='col-1 fs-20' />
                      <Select aria-label='ftbv-budget' placeholder='' isSearchable={false} options={budgetOption} value={selectedBudget} onChange={(e) => handlePriceChange(e)} className='col-11 d-inline-block' />
                    </div>

                  </div>
                  <div className='d-inline-block col-6 col-md-3 pt-2 pb-1 pt-md-2 pb-md-0 new-ftbv-range-border  px-3'>
                    <h2 className='fs-16 fw-500 mb-0'>Range</h2>
                    <div className='col-12 budget-container'>
                      <BsSpeedometer className='col-1' />
                      <Select placeholder='' aria-label='ftbv-range' isSearchable={false} options={rangeOption} value={selectedRange} onChange={(e) => handleRangeChange(e)} className='col-11 d-inline-block' />
                    </div>

                  </div>
                  <div className='col-12 d-inline-block px-0 col-md-3 py-md-0 new-ftbv-search-border  rounded-2'>

                    <button
                      className='w-100 rounded-2 new-ftbv-search-btn text-white bg-yellow border-0 py-1 fs-5 fw-500'
                      onClick={(e) => onSearchClick(e)}
                    >
                      SEARCH
                    </button>
                  </div>
                </div>

              </div>
            </div>
            {/* <Ftbv /> */}
            {/* ----------- FTBV Form end ----------- */}

            {/* ----------- Popular Vehicles section start ----------- */}

            <PopularVehicles type={'popular'} />


            {/* ----------- Popular Vehicles section end ----------- */}

            {comparevehicles.length > 0 && <VehicleCompareCard type={1} /> }

            {/* PopulaBrand section start */}

            <PopularBrands />

            {/* PopulaBrand section start */}







            {/* ----------- Latest Vehicles section start ----------- */}

            {latestElectricScooter?.docs?.length > 0 && <LatestVehicles title={'Latest Electric Scooters'} type={'latest'} subType={1} data={latestElectricScooter} />}
            {latestElectricBike?.docs?.length > 0 && <LatestVehicles title={'Latest Electric Bikes'} type={'latest'} subType={2} data={latestElectricBike} />}
            {/* {latestElectricCar?.docs?.length > 0 && <LatestVehicles title={'Latest cars'} type={'latest'} subType={3} data={latestElectricCar} />} */}

            {/* ----------- Latest Vehicles section end ----------- */}

            {/* ----------- Commercial Vehicles section start ----------- */}

            {/* {commercialVehicles?.docs?.length > 0 && <LatestVehicles title={'Commercial vehicles'} type={'commercial'} data={commercialVehicles} />} */}

            {/* ----------- Commercial Vehicles section end ----------- */}


            {/* ----------- Upcoming Vehicles section start ----------- */}
            <CommonParentSection title={'Upcoming Electric Vehicles'} type={'upcoming'}>
              {upcomingList?.docs?.length > 0 &&
                upcomingList?.docs?.length > 0 && !loading ? (
                <div className='position-relative' >
                  <button
                    className='scroll-btn scroll-btn-position d-none d-xl-block'
                    style={{ top: '11rem' }}
                    onClick={() => HandleScroll('left')}
                    aria-label="Scroll to upcoming vehicles on the left"
                  >
                    <AiOutlineLeft id='left-scroll' className='Right-left-icon' />
                  </button>
                  <div
                    className='d-flex justify-content-start align-items-center flex-row scroll-container'
                    id='upcoming-container'
                    style={{ height: '410px' }}
                  >
                    {upcomingList?.docs?.length > 0 &&
                      !loading &&
                      upcomingList.docs.map((vehicle) => (
                        <div
                          className=' d-inline-block me-3'
                          key={vehicle._id}
                        >
                          <HomeUpcomingVehicleCard
                            vehicleData={vehicle}
                            label={'Upcoming'}
                            subtype={"Upcoming Vehicles"}
                            type={1}
                          />
                        </div>
                      ))}
                  </div>
                  <button
                    className='scroll-btn scroll-btn-right-position d-none d-xl-block'
                    style={{ top: '11rem' }}
                    onClick={() => HandleScroll('right')}
                    aria-label="Scroll to upcoming vehicles on the right"
                  >
                    <AiOutlineRight id="right-scroll" className='Right-left-icon ' />
                  </button>
                </div>
              ) : (
                <div className='text-center color-d-gray py-5 fs-6'>
                  No Upcoming vehicles Found
                </div>
              )

              }

            </CommonParentSection>
            {/* ----------- Upcoming Vehicles section end ----------- */}


            {/* ----------- Latest Review start ----------- */}

            {/* {latestReviews?.docs?.length > 0 && <LatestReviews />} */}

            {/* ----------- Latest Review end ----------- */}




            {/* ----------- Blog start ----------- */}
            <CommonParentSection title={'EV Blog'} type={'blog'}>
              <BlogSection type={1} />
            </CommonParentSection>
            {/* ----------- Blog start ----------- */}
            {/* {sponser && <Sponserproducts type={'sponsor'} />} */}

            <CommonParentSection title={'EV news'} type={'news'}>
              {/* <NewsSection /> */}
              <NewsSectionCard type={1} />
            </CommonParentSection>

            {/* ----------- FAQ start ----------- */}

            {faqList?.docs?.length > 0 && <FaqSection type={1} />}

            {/* ----------- FAQ end ----------- */}
            {/* ------------------ Subscribe Section start ------------------- */}
            <SubscribeSection />
            {/* ------------------ Subscribe Section end ------------------- */}

            <SignUpDetailsModal
              openModal={isOpenSignUpModal}
              onCloseModal={(e) => {
                setIsOpenSignUpModal(false);
              }}
              filterData={availableFilterData}
            />
          </div>
        </div>
      </section>
      <FloatingActionButton/>
    </section>
  );
};

export default Index;

