import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonParentSection from './CommonParentSection';
import HomePageVehicledetailsCard from './HomePageVehicledetailsCard';
import { HomePageVehicleLoader } from '../../../common_components/Loader/Index';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

export default function PopularVehicles({ type }) {
  // console.log("Popular vehicle is Rendered");
  let { popularVehicles } = useSelector(
    (state) => state.home
  );
  // const [vehicles, setVehicles] = useState([]);
  const [vehicleLoading, setVehicleLoading] = useState(true);
  const [count,setCount] = useState(4);
  const [Limit,setLimit] = useState(10);
  const [current,setcurrent] = useState(7);
  const [slicesize,setSliceSize] = useState(8);

  const [fetchLoading, setFetchLoading] = useState(false);

  useEffect(() => {
    if(count % 7 === 0 && count < 14) {
      if(count >= current) {
        setSliceSize(slicesize+8);
        setLimit(Limit+6)
        setFetchLoading(false)
      setcurrent(count);
    }
    else {
      setSliceSize(slicesize-8)
      setLimit(Limit-6)
     if(count === 7) {
     setcurrent(count)
     }
    }
  }
  },[count])

  const HandleScroll = (direction) => {
    var container = document.getElementById('pop-container')
    if (direction === 'left') {
      container.scrollLeft -= 265;
      if(count > 4) {
      setCount(count-1);
      }
    } 
    else {
      container.scrollLeft += 265;  
      if(count > 3) {
      setCount(count+1);
      // console.log(count+1)
      }
    }
  }

  const renderPopularvehicles = useMemo(
    () =>
      popularVehicles?.docs?.length > 0
        ? popularVehicles?.docs?.slice(0, slicesize).map((vehicle) => (
            <div className='d-inline-block me-3'>
              <HomePageVehicledetailsCard
                vehicleData={vehicle}
                label={'Popular'}
                subtype = {'Popular Vehicles'}
              />
            </div>
          ))
        : null,
    [popularVehicles, vehicleLoading, slicesize]
  );
  return (
    <Fragment>
      <CommonParentSection title={'Popular Electric Vehicles'} type={type}>
        {popularVehicles?.docs?.length > 0 ? (
          <div className='position-relative'>
            <button
              className='scroll-btn scroll-btn-position d-none d-xl-block'
              onClick={() => HandleScroll('left')}
              style={{ top: '11rem' }}
              aria-label="Scroll to popular vehicles on the left"
            >
              <AiOutlineLeft id = 'popular-vehicle-left' className='Right-left-icon' />
            </button>
            <div
              className='d-flex justify-content-between align-items-center flex-row scroll-container'
              id='pop-container'
              style={{ height: '410px' }}
            >
              {renderPopularvehicles}
            </div>
            <button
              className='scroll-btn scroll-btn-right-position d-none d-xl-block'
              onClick={() => HandleScroll('right')}
              style={{ top: '11rem' }}
              aria-label="Scroll to popular vehicles on the right"
            >
              <AiOutlineRight id = 'popular-vehicle-right' className='Right-left-icon ' />
            </button>
          </div>
        ) : (
          <HomePageVehicleLoader />
        )}
      </CommonParentSection>
    </Fragment>
  );
}

